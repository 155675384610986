var now = null;

/**
 * Hides or shows carousel arrows based on scroll position
 */
function toggleCustomCarouselControls() {
    var $scrollbar = $('.custom-scrollbar.active');
    var $carousel = $scrollbar.closest('.pd-ao-carousel');
    var marginOffset = parseFloat($scrollbar.css('margin-left')) + parseFloat($scrollbar.css('margin-right'));
    $carousel.find('.custom-carousel-control--prev').toggleClass('d-none', Math.floor($scrollbar.scrollLeft()) === 0);
    $carousel.find('.custom-carousel-control--next').toggleClass('d-none', Math.floor($scrollbar.get(0).scrollWidth - $scrollbar.scrollLeft() + marginOffset) <= Math.ceil($carousel.outerWidth()));
    $scrollbar.removeClass('active');
}

/**
 * Creates interval for scroll listener
 */
function startInterval() {
    var interval = setInterval(function () {
        if (Date.now() > now + 250) {
            clearInterval(interval);
            now = null;
            toggleCustomCarouselControls();
        }
    }, 250);
}

module.exports = function () {
    $('.custom-scrollbar').on('scroll', function () {
        $(this).addClass('active');
        if (now === null) {
            now = Date.now();
            startInterval();
        } else {
            now = Date.now();
        }
    });

    $(document).on('click', '.custom-carousel-control--prev', function (e) {
        e.preventDefault();
        var $this = $(this);
        var carouselElement = $this.closest('.pd-ao-carousel').find('.custom-scrollbar');
        var imageSize = $this.closest('.pd-ao-carousel').find('.each-product')[0].offsetWidth;
        var currentCaroBarPosition = carouselElement.scrollLeft();
        carouselElement.animate(
            {
                scrollLeft: (currentCaroBarPosition - imageSize)
            },
            1000
        );
    });

    $(document).on('click', '.custom-carousel-control--next', function (e) {
        e.preventDefault();
        var $this = $(this);
        var carouselElement = $this.closest('.pd-ao-carousel').find('.custom-scrollbar');
        var imageSize = $this.closest('.pd-ao-carousel').find('.each-product')[0].offsetWidth;
        var currentCaroBarPosition = carouselElement.scrollLeft();
        carouselElement.animate(
            {
                scrollLeft: (currentCaroBarPosition + imageSize)
            },
            1000
        );
    });
};
